import { Box, makeStyles, Paper, TableContainer, Tooltip, Typography } from '@material-ui/core'
import { ConditionBadge } from 'components/facets/ConditionBadge'
import firstEdIcon from 'img/firstEdIcon.png'
import foilIcon from 'img/foilIcon.png'
import reverseHoloIcon from 'img/reverseHoloIcon.png'
import { default as alteredIcon, default as signedIcon } from 'img/signedIcon.png'
import { useOrderStore } from 'lib/hooks'
import { getGameCodeFromName, getPlaysetCoefficient } from 'lib/utils'
import { ReactElement, useEffect, useState } from 'react'
import {
  AutoSizer,
  CellMeasurer,
  CellMeasurerCache,
  Column,
  SortDirection,
  SortDirectionType,
  Table,
} from 'react-virtualized'
import { MeasuredCellParent } from 'react-virtualized/dist/es/CellMeasurer'
import Flag from 'react-world-flags'
import { getGameFromId, getGameMetaData, OrderArticle } from 'shared'
import { LANGUAGE_MAP_ICONS } from 'utils/constants'
import { PickingButton } from './PickingButton'

export interface PickingTableProps {
  type: string
  handlePick?: (article: OrderArticle) => void
  handleCompletePicking?: (article: OrderArticle, quantity: number) => void
  handleReportIssue?: (a: OrderArticle) => void
  handleBackToPicking?: (a: OrderArticle) => void
  handleIssues?: (a: OrderArticle) => void
  oneClickPick: boolean
}

export const PickingTable = ({
  type,
  handlePick,
  handleCompletePicking,
  handleReportIssue,
  handleBackToPicking,
  handleIssues,
  oneClickPick,
}: PickingTableProps): JSX.Element => {
  const articlesToPick = useOrderStore((state) => state.articlesToPick)
  const articlesPicked = useOrderStore((state) => state.articlesPicked)
  const articlesWithIssues = useOrderStore((state) => state.articlesWithIssues)
  const [hidePickingButton, setHidePickingButton] = useState<boolean>(oneClickPick)

  useEffect(() => {
    setHidePickingButton(oneClickPick)
  }, [oneClickPick])

  const iconHeight = '17px'
  // const defaultCardWidth = '210px'

  const getTableArticles = () => {
    if (type === 'toPick') {
      return articlesToPick
    } else if (type === 'picked') {
      return articlesPicked
    } else {
      return articlesWithIssues
    }
  }
  const tableArticles = getTableArticles()

  // const getGameCodeFromIdGame = (idGame?: number) => {
  //   const gameCodeMap: {
  //     [key: number]: string
  //   } = {
  //     1: 'mtg',
  //     3: 'yug',
  //     6: 'pok',
  //     16: 'fab',
  //   }
  //   if (idGame) {
  //     return gameCodeMap[idGame]
  //   } else {
  //     return undefined
  //   }
  // }

  const useStyles = makeStyles(() => ({
    row: {
      borderBottom: 'solid 1px lightgrey',
    },
    toPick: {
      marginBottom: '20px',
      padding: '10px',
      borderRadius: '5px',
      backgroundColor: 'white',
    },
    picked: {
      marginBottom: '20px',
      padding: '10px',
      borderRadius: '5px',
      backgroundColor: '#70bf4c',
      color: 'white',
      width: 'calc(100vw - 300px) !important',
    },
    withIssues: {
      marginBottom: '20px',
      padding: '10px',
      borderRadius: '5px',
      backgroundColor: '#f15e3e',
      color: 'white',
      width: 'calc(100vw - 300px) !important',
    },
    imageList: {
      flexWrap: 'nowrap',
      padding: '20px',
    },
  }))
  const styles = useStyles()

  const cache = new CellMeasurerCache({
    fixedWidth: true,
    minHeight: 40,
  })

  const cellRendererMeasurer = (
    parent: MeasuredCellParent,
    rowIndex: number | undefined,
    content: ReactElement
  ) => {
    return (
      <CellMeasurer cache={cache} parent={parent} columnIndex={0} rowIndex={rowIndex}>
        <div
          style={{
            whiteSpace: 'normal',
          }}
        >
          {content}
        </div>
      </CellMeasurer>
    )
  }

  // columns sorting
  const [sortBy, setSortBy] = useState<string>('datePaid')
  const [sortDirection, setSortDirection] = useState<SortDirectionType>(SortDirection.DESC)
  const sort = ({ sortBy }: { sortBy: string }) => {
    setSortBy(sortBy)
    setSortDirection(sortDirection === SortDirection.ASC ? SortDirection.DESC : SortDirection.ASC)
    let sortedArticles = [...tableArticles]

    if (sortBy === 'name') {
      sortedArticles = tableArticles.sort((a, b) => {
        return a.product.locName < b.product.locName ? 1 : -1
      })
    } else if (sortBy === 'expansion') {
      sortedArticles = tableArticles.sort((a, b) => {
        return a.product.expansion < b.product.expansion ? 1 : -1
      })
    } else if (sortBy === 'cn') {
      sortedArticles = tableArticles.sort((a, b) => {
        if (a.product.nr && b.product.nr) {
          return a.product.nr < b.product.nr ? 1 : -1
        } else if (a.product.nr) {
          return 1
        } else {
          return -1
        }
      })
    } else if (sortBy === 'rarity') {
      sortedArticles = tableArticles.sort((a, b) => {
        if (a.product.rarity && b.product.rarity) {
          const rarities = getGameMetaData(a.product.idGame)?.cardmarketRarityToNumberMap
          return rarities[a.product.rarity] < rarities[b.product.rarity] ? 1 : -1
        } else if (a.product.rarity) {
          return 1
        } else {
          return -1
        }
      })
    } else if (sortBy === 'colors') {
      sortedArticles = tableArticles.sort((a, b) => {
        if (a.product.colors && b.product.colors) {
          return a.product.colors < b.product.colors ? 1 : -1
        } else if (a.product.colors) {
          return 1
        } else {
          return -1
        }
      })
    } else if (sortBy === 'quantity') {
      sortedArticles = tableArticles.sort((a, b) => {
        return a.count < b.count ? 1 : -1
      })
    } else if (sortBy === 'price') {
      sortedArticles = tableArticles.sort((a, b) => {
        return a.price < b.price ? 1 : -1
      })
    } else if (sortBy === 'comments') {
      sortedArticles = tableArticles.sort((a, b) => {
        return a.comments < b.comments ? 1 : -1
      })
    }

    if (sortDirection === SortDirection.DESC) {
      sortedArticles = sortedArticles.reverse()
    }
    return sortedArticles
  }

  return (
    <>
      <Box className={`responsive-table-container ${styles[type as keyof typeof styles]}`}>
        <TableContainer component={Paper}>
          <AutoSizer disableHeight>
            {({ width }) => (
              <Table
                deferredMeasurementCache={cache}
                width={Math.max(width, 1200)}
                height={500}
                headerHeight={30}
                rowHeight={cache.rowHeight}
                rowCount={tableArticles.length}
                rowGetter={({ index }) => tableArticles[index]}
                rowClassName={styles.row}
                sort={sort}
                sortBy={sortBy}
                sortDirection={sortDirection}
                className={`picking-table`}
              >
                <Column
                  width={30}
                  label=""
                  dataKey=""
                  disableSort={true}
                  cellRenderer={({ rowData: a }) => (
                    <>
                      <Tooltip title={getGameFromId(a.product.idGame) || ''}>
                        <span
                          className={`ss-${getGameCodeFromName(
                            getGameFromId(a.product.idGame)
                          )} ss-game-icon ss-fw`}
                          style={{ fontSize: '1.5rem' }}
                        ></span>
                      </Tooltip>
                    </>
                  )}
                />

                <Column
                  width={width * 0.14}
                  label="Name"
                  dataKey="name"
                  cellRenderer={({ rowData: a }) => (
                    <>
                      <Box>{a.product.locName}</Box>
                      {a.language.languageName !== 'English' && <Box>[{a.product.enName}]</Box>}
                    </>
                  )}
                />

                <Column
                  width={width * 0.07}
                  label="Expansion"
                  dataKey="expansion"
                  cellRenderer={({ rowData: a }) => <>{a.product.expansion}</>}
                />

                <Column
                  width={45}
                  label="CN"
                  dataKey="cn"
                  cellRenderer={({ rowData: a }) => (
                    <>{a.product.idGame !== 99 && <>{a.product.nr}</>}</>
                  )}
                />

                <Column
                  width={width * 0.06}
                  label="Rarity"
                  dataKey="rarity"
                  cellRenderer={({ rowData: a }) => <>{a.product.rarity}</>}
                />

                <Column
                  width={65}
                  label="Colors"
                  dataKey="colors"
                  cellRenderer={({ rowData: a }) => <>{a.product.colors}</>}
                />

                <Column
                  width={40}
                  label="Qty"
                  dataKey="quantity"
                  cellRenderer={({ rowData: a }) => (
                    <>{a.isPlayset ? a.count * getPlaysetCoefficient(a.product.idGame) : a.count}</>
                  )}
                />

                <Column
                  width={width * 0.06}
                  label="Price"
                  dataKey="price"
                  cellRenderer={({ rowData: a }) => <>{a.price}</>}
                />

                <Column
                  width={width * 0.08}
                  label="Properties"
                  dataKey=""
                  disableSort={true}
                  cellRenderer={({ rowData: a }) => (
                    <>
                      <Box display="flex" alignItems="center">
                        <Box paddingRight={1}>
                          <Flag
                            fallback={<span>Unknown</span>}
                            height="10px"
                            width="20px"
                            code={LANGUAGE_MAP_ICONS[a.language.idLanguage] || 'gg'}
                            style={{ alignSelf: 'center' }}
                          />
                        </Box>
                        <ConditionBadge condition={a.condition} />
                        {a.isFoil && <img alt="foil" src={foilIcon} height={iconHeight} />}
                        {a.isAltered && <img alt="altered" src={alteredIcon} height={iconHeight} />}
                        {a.isSigned && <img alt="signed" src={signedIcon} height={iconHeight} />}
                        {a.isFirstEd && (
                          <img
                            alt="firstEd"
                            height="17px"
                            src={firstEdIcon}
                            style={{ alignSelf: 'center', marginLeft: '2px', marginRight: '2px' }}
                          />
                        )}
                        {a.isReverseHolo && (
                          <img
                            alt="reverseHolo"
                            height="17px"
                            src={reverseHoloIcon}
                            style={{ alignSelf: 'center', marginLeft: '2px', marginRight: '2px' }}
                          />
                        )}
                      </Box>
                    </>
                  )}
                />

                <Column
                  width={width * 0.1}
                  label="Comments"
                  dataKey="comments"
                  cellRenderer={({ rowData: a, parent, rowIndex }) =>
                    cellRendererMeasurer(parent, rowIndex, <>{a.comments}</>)
                  }
                />

                {type === 'toPick' && (
                  <Column
                    width={130}
                    label=""
                    dataKey=""
                    cellRenderer={({ rowData: a }) => (
                      <PickingButton type="pick" article={a} handlePick={handlePick} />
                    )}
                  />
                )}

                {type === 'toPick' && (
                  <Column
                    width={130}
                    dataKey=""
                    label="PID"
                    cellRenderer={({ rowData: a }) => (
                      <>
                        {hidePickingButton ? (
                          <Typography variant="body1">{a.pickingId}</Typography>
                        ) : (
                          <PickingButton
                            type="put"
                            article={a}
                            handleCompletePicking={handleCompletePicking}
                          />
                        )}
                      </>
                    )}
                  />
                )}

                {type === 'toPick' && (
                  <Column
                    width={130}
                    label=""
                    dataKey=""
                    cellRenderer={({ rowData: a }) => (
                      <>
                        <PickingButton
                          type="reportIssue"
                          article={a}
                          handleReportIssue={handleReportIssue}
                        />
                      </>
                    )}
                  />
                )}

                {['picked', 'withIssues'].includes(type) && (
                  <Column
                    width={130}
                    label=""
                    dataKey=""
                    cellRenderer={({ rowData: a }) => (
                      <>
                        <PickingButton
                          type="backToPicking"
                          article={a}
                          handleBackToPicking={handleBackToPicking}
                        />
                      </>
                    )}
                  />
                )}

                {['picked', 'withIssues'].includes(type) && (
                  <Column
                    width={130}
                    dataKey=""
                    label="PID"
                    cellRenderer={({ rowData: a }) => (
                      <>
                        <Typography variant="body1">{a.pickingId}</Typography>
                      </>
                    )}
                  />
                )}

                {['withIssues'].includes(type) && (
                  <Column
                    width={130}
                    label=""
                    dataKey=""
                    cellRenderer={({ rowData: a }) => (
                      <>
                        <PickingButton type="issues" article={a} handleIssues={handleIssues} />
                      </>
                    )}
                  />
                )}
              </Table>
            )}
          </AutoSizer>
        </TableContainer>
      </Box>
    </>
  )
}
